<template>
  <div>Show</div>
</template>

<script>
export default {
}
</script>

<style scoped>

</style>